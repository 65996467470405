
import React, { useEffect, useRef, useState } from 'react';
import '../../ressources/styles/index.css';
import Typewriter from 'typewriter-effect/dist/core';
import Button from '../../components/Button';
import equipeImg from '../../ressources/images/fondateurs.JPG';
import LaVieChezOrmaes1 from '../../ressources/images/La vie chez Ormaes 1.jpg';
import LaVieChezOrmaes2 from '../../ressources/images/La vie chez Ormaes 2.jpg';
import LaVieChezOrmaes3 from '../../ressources/images/La vie chez Ormaes 3- ter.jpg';
import LaVieChezOrmaes4 from '../../ressources/images/La vie chez Ormaes 3.jpg';
import LaVieChezOrmaes5 from '../../ressources/images/La vie chez Ormaes 5.jpg';
import LaVieChezOrmaes6 from '../../ressources/images/La vie chez Ormaes 6.jpg';
import ImageTasse from '../../ressources/images/tasse.jpg';
import Caffart_CTS from '../../ressources/images/T_ACAFFART_CTS.svg'
import Caffart_CTS_EN from '../../ressources/images/T_ACAFFART_CTS_EN.svg'
import Lakhlef_WEEWIO from '../../ressources/images/T_SLAKHLEF_WEEWIO.svg'
import Lakhlef_WEEWIO_EN from '../../ressources/images/T_SLAKHLEF_WEEWIO_EN.svg'
import Marque_Alsace from '../../ressources/images/Marque_Alsace.png'
import Temoignages from '../Accueil/Content/Temoignages';
import { Helmet } from 'react-helmet';
import CurseurBertrand from '../../ressources/images/Curseur_Bertrand.png';
import CurseurLaurent from '../../ressources/images/Curseur_Laurent.png';
import CurseurEric from '../../ressources/images/Curseur_Eric.png';
import { Link } from 'react-router-dom';
import i18n from "../../I18next/i18n";
import { useTranslation } from 'react-i18next';

export default function QuiSommesNous() {
    const { t } = useTranslation();
    const titleRef = useRef(null);
    const [bertrandClickCount, setBertrandClickCount] = useState(0);
    const [laurentClickCount, setLaurentClickCount] = useState(0);
    const [ericClickCount, setEricClickCount] = useState(0);
    
    const handleImageClick = (image) => {
        if (image === 'bertrand') {
            setBertrandClickCount((prevCount) => prevCount + 1);
            if (bertrandClickCount + 1 === 5) {
                document.body.style.cursor = `url(${CurseurBertrand}), auto`;
                setBertrandClickCount(0);
            }
        } else if (image === 'laurent') {
            setLaurentClickCount((prevCount) => prevCount + 1);
            if (laurentClickCount + 1 === 5) {
                document.body.style.cursor = `url(${CurseurLaurent}), auto`;
                setLaurentClickCount(0);
            }
        } else if (image === 'eric') {
            setEricClickCount((prevCount) => prevCount + 1);
            if (ericClickCount + 1 === 5) {
                document.body.style.cursor = `url(${CurseurEric}), auto`;
                setEricClickCount(0);
                setEricClickCount(5)
            }
        }
    };

    useEffect(() => {
        const title = titleRef.current;
        const typewriter = new Typewriter(title, {
        loop: true
        });
        typewriter.typeString(t("qsn_who"))
        .deleteAll()
        .pauseFor(1500)
        .deleteAll()
        .start();
    }, [t]);

    const [isMouseOver, setIsMouseOver] = useState(false);
    const handleMouseOver = () => {
        setIsMouseOver(true);
      };
      
      const handleMouseOut = () => {
        setIsMouseOver(false);
      };

    return (
        <>

<Helmet>
                    <title>{t("qsn_title")}</title>
                </Helmet>
                <div className="overflow-x-hidden">
  <div className="landing-img flex items-center justify-center pt-16 max-h-52">
    <h1 className="landing-title" ref={titleRef}>
      Ormaes
    </h1>
  </div>

  <div className="wrapper mt-10" data-aos="fade-right">
    <p className="text-primOrange font-bold text-center text-2xl mb-5">
    {t("qsn_apprenez")}
    </p>
    <h1 className="big-title">{t("qsn_confiance1")}</h1>
  </div>

  <div className="flex justify-center flex-col gap-32 mt-12">
    <div
      className="flex flex-col lg:flex-row justify-between gap-8 wrapper"
      data-aos="fade-left"
    >
      <div className="w-full lg:w-5/12 flex flex-col justify-center">
        <h2 className="second-title">{t("qsn_about")}</h2>
        <p className="paragraph">
        {t("qsn_about_text")}
        </p>
        <h2 className="second-title mt-9">{t("qsn_histoire")}</h2>
        <p className="paragraph">
        {t("qsn_histoire_text")}
        </p>
      </div>
            <div className="w-full lg:w-8/12">
        <img className="w-full h-full" src={equipeImg} alt="Membres équipe" />
      </div>
        </div>
        <div className="wrapper w-full" data-aos="fade-right">
        <h2 className="second-title">{t("qsn_localisation")}</h2>
        <div className="flex mt-8 w-full">
           <img className="2xl:w-1/5 xl:w-1/5 lg:w-1/4 md:w-1/4 sm:w-1/4 mx-auto w-60 " src={Marque_Alsace} alt="Marque Alsace" />
   </div> 
      </div>  
    <div
      className="flex lg:flex-row flex-col justify-between gap-8 pt-12 wrapper"
      data-aos="fade-right"
    >
      <div className="w-full lg:pt-8">
        <h2 className="second-title mb-16 text-left">{t("qsn_equipe")}</h2>
        <div className="flex w-full rounded-md flex-wrap gap-8 justify-center">
          <div className="rounded-lg img-laurent img-pers" onClick={() => handleImageClick("laurent")}>
            <a href='https://www.linkedin.com/in/laurent-burger-2491a455' target='_blank'>  
              <p className="img-overlay">Laurent Burger</p>
              <p className="img-overlay metier">
                {t("qsn_burger1")} <br /> {t("qsn_burger2")}
              </p>
            </a>  
          </div>
          <div className="rounded-lg img-eric img-pers" onClick={() => handleImageClick("eric")}>
            <a href='https://www.linkedin.com/in/ericjolin' target='_blank'>
              <p className="img-overlay">Eric Jolin</p>
              <p className="img-overlay metier">
              {t("qsn_jolin1")} <br /> {t("qsn_jolin2")}
              </p>
            </a>
          </div>
          <div className="rounded-lg img-vincent img-pers">
            <p className="img-overlay">Vincent Klein</p>
            <p className="img-overlay metier">
            {t("qsn_klein1")} <br /> {t("qsn_klein2")}
            </p>
          </div>
          <div className="rounded-lg img-victor img-pers">
            <a href='https://www.linkedin.com/in/victor-talbot-b60763169' target='_blank'>
              <p className="img-overlay">Victor Talbot</p>
              <p className="img-overlay metier">
              {t("qsn_talbot1")} <br /> {t("qsn_talbot2")}
              </p>
            </a>
          </div>
          <div className="rounded-lg img-malaury img-pers">
            <a href='https://www.linkedin.com/in/malaury-boudon-a9538a12b' target='_blank'>   
              <p className="img-overlay">Malaury Boudon</p>
              <p className="img-overlay metier">
              {t("qsn_boudon1")} <br /> {t("qsn_boudon2")}
              </p>
            </a>
          </div>
          <div className="rounded-lg img-thomas img-pers">
          <a href='https://www.linkedin.com/in/thomas-buresi-55a4001b9' target='_blank'>   
            <p className="img-overlay">Thomas Buresi</p>
            <p className="img-overlay metier">
            {t("qsn_buresi1")} <br /> {t("qsn_buresi2")}
            </p>
          </a>
          </div>
      
        </div>
      </div>
    </div>
    <div
      className="flex flex-col lg:flex-row justify-between gap-8 mt-12 wrapper"
      data-aos="fade-left"
    >
      <div className="w-full flex flex-col justify-center gap-7">
        <h2 className="value-bigtitle text-left">{t("qsn_valeurs")}</h2>
        <div>
          <h3 className="value-subtitle">{t("qsn_professionalisme")}</h3>
          <p className="paragraph">
          {t("qsn_professionalisme_text")}
          </p>
        </div>
        <div>
          <h3 className="value-subtitle">{t("qsn_entrepreunariat")}</h3>
          <p className="paragraph">
          {t("qsn_entrepreunariat_text")}
          </p>
        </div>
        <div>
          <h3 className="value-subtitle">
            <Link to="/teapot">{t("qsn_cafetiere")}</Link>
          </h3>
          <p className="paragraph">
          {t("qsn_cafetiere_text")}
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col justify-center gap-7">
        <img
            className="rounded-xl block w-8/12 "
            src={ImageTasse}
            alt="tasse à café"
          />
      </div>
    </div>
    <div className="wrapper w-full" data-aos="fade-right">
      <h2 className="second-title text-left ">{t("qsn_vie")}</h2>
      <div className="flex flex-col md:flex-row justify-between  gap-8 mb-7">
        <p className="paragraph text md:w-1/2">
        {t("qsn_vie_text1")}

        </p>
        <p className="paragraph text md:w-1/2">
        {t("qsn_vie_text2")}
        </p>
      </div>
      <p className="paragraph text-center mb-7">
      {t("qsn_vie_text3")}
      </p>
      <div className="flex flex-col gap-5 justify-center sm:flex-row sm:flex-wrap">
        <img
          src={LaVieChezOrmaes1}
          alt="ormaes1"
          className="w-full max-w-[545px]"
        />
        <img
          src={LaVieChezOrmaes2}
          alt="ormaes2"
          className="w-full max-w-[545px]"
        />
        <img
          src={LaVieChezOrmaes3}
          alt="ormaes3"
          className="w-full max-w-[545px]"
        />
        <img
          src={LaVieChezOrmaes4}
          alt="ormaes4"
          className="w-full max-w-[545px]"
        />
        <img
          src={LaVieChezOrmaes6}
          alt="ormaes6"
          className="w-full max-w-[545px]"
        />
        <img
          src={LaVieChezOrmaes5}
          alt="ormaes5"
          className="w-full max-w-[545px]"
        />
      </div>
      <div className="flex justify-center w-full mt-9">
        <Link
          to=""
          className="px-8 py-2 bg-primOrange rounded-[19px] text-white font-bold md:px-10 md:py-3 md:text-md hover:bg-gray-700 transition-all duration-200;"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          {isMouseOver ? t("qsn_en_cours1") : t("qsn_en_cours2")}
        </Link>
      </div>
      <div className="pt-16 mb-16 md:mb-36">
        <div className="w-11/12 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto sm:mb-10 mb-16">
          <h1 className="second-title text-center">
            {t("qsn_confiance")}
          </h1>
          <p className="text-base md:text-lg lg:text-xl text-center text-gray-600 xl:w-10/12 xl:mx-auto">
            {t("qsn_confiance_text")}
          </p>
        </div>
      </div>
   
{/* EJO changer le code pour le rencre plus propre */}      
      {(i18n.language === "fr" ? 
        <div className="flex flex-row flex-wrap w-full justify-center mt-8">
          <img className="2xl:w-1/3 xl:w-1/3 lg:w-1/2 md:w-2/3 sm:w-2/3 mx-auto mb-10  " src={Caffart_CTS} alt="Témoignage Alain CAFFART - CTS" />
          <img className="2xl:w-1/3 xl:w-1/3 lg:w-1/2 md:w-2/3 sm:w-2/3 mx-auto mb-10  " src={Lakhlef_WEEWIO} alt="Témoignage Simon LAKHLEF - WEEWIO" />
        </div> :
        <div className="flex flex-row flex-wrap w-full justify-center mt-8">
          <img className="2xl:w-1/3 xl:w-1/3 lg:w-1/2 md:w-2/3 sm:w-2/3 mx-auto mb-10  " src={Caffart_CTS_EN} alt="Testimonial Alain CAFFART - CTS" />
          <img className="2xl:w-1/3 xl:w-1/3 lg:w-1/2 md:w-2/3 sm:w-2/3 mx-auto mb-10  " src={Lakhlef_WEEWIO_EN} alt="Testimonial Simon LAKHLEF - WEEWIO" />
        </div>
              )}
   
  <div className="h-2 2xl:h-8 xl:h-8 lg:h-8 md:h-6 sm:h-4 bg-gradient-to-r from-[#0400CF] to-[#FF9703]"></div>
      <div className="mt-200 mb-10 lg:mt-12 w-full">
        <Temoignages />
        <div className="decouvrir-img h-60 bg-no-repeat w-full bg-cover text-white flex justify-center flex-col md:h-72 xl:h-80">
          <h1
            className="text-3xl font-bold text-center mb-2 md:text-4xl xl:text-5xl"
            data-aos="fade-left"
          >
            {t("qsn_decouvrir1")}
            <span className="text-primOrange"> {t("qsn_decouvrir2")}</span>
          </h1>

          <div className="flex justify-center mt-7" data-aos="fade-left">
            <Button to="../notre-offre" texte={t("qsn_decouvrir3")}  />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>;

        </>
    )
}
