import i18n from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      supportedLngs: ['en', 'fr'],
      fallbackLng: "en",
      interpolation: {
        escapeValue: false,
      },  


      fr: {
        translation: {

// avec formattage HTML directement dans la traduction pour la page landing}
//            "accueil_header_fr": 
//                <h1 className="text-3xl font-bold text-center mb-2 md:text-4xl xl:text-left xl:text-4xl text-gray-800">
//                Agence de 
//                <span className="text-primOrange"> développement </span>
//                et de
//               <span className="text-primOrange"> conseil informatique</span>  
//                </h1>,

// Landing
            "accueil_header": "<h1 class=\"text-3xl font-bold text-center mb-2 md:text-4xl xl:text-left xl:text-4xl text-gray-800\">" +
                "               Agence de <span class=\"text-primOrange\">développement</span> et de <span class=\"text-primOrange\">conseil informatique</span></h1>",
            "landing_content": "Partenaire de vos projets digitaux, ORMAES déploie une approche qui allie la maîtrise technique, un haut niveau de qualité de service et le bien-être de l'équipe.",
            "accueil_trust": "Ils nous font confiance",
            "accueil_parler_projet": "PARLONS DE VOTRE PROJET",
            "accueil_confiance": "Ils nous font confiance",
            "accueil_developpement": "Développement",
            "accueil_integration": "Intégration",
            "accueil_expertise": "Expertise",
            
// Header            
            "header_accueil": "Accueil",
            "header_qui": "Qui sommes-nous ?",
            "header_offers": "Notre offre",
            "header_career": "Carrières",
            "header_contact": "Contact",

//About
            "about_qui": "Qui ",  
            "about_sommes-nous": "sommes-nous",
            "about_ormaes_1": "Ormaes est une société fondée par un duo d'amoureux du service, des projets techniques, de l'engagement et de la bonne humeur. Nous croyons en un état d'esprit singulier où la finalité prime sur les règles établies, et où la satisfaction du client à travers une relation partenariale forte est incarnée au quotidien.",
            "about_ormaes_2": "Nous nous considérons comme exigeants, responsables, empathiques, nous savons responsabiliser tout comme accorder notre confiance. Nous reconnaissons le travail bien fait, l'effort et l'implication. Nous sommes également capables de reconnaître nos erreurs, de faire grandir et de donner du sens. Nous aimons notre travail et sommes fiers de ce que nous accomplissons.",
            "about_ormaes_decouvrir": "Nous découvrir",

//QuiSommesNous
            "qsn_who": "Qui sommes-nous ?",
            "qsn_apprenez": "Apprenez à nous connaître",
            "qsn_title": "Ormaes | Qui sommes-nous ?",
            "qsn_confiance1": "Voici de quoi vous inspirer confiance.",
            "qsn_about": "A propos de nous",
            "qsn_about_text": "Ormaes est une société (SAS) de prestation de services informatiques fondée en novembre 2022 par 2 associés co-fondateurs : Eric JOLIN et Laurent BURGER . Ils disposent d’une expérience significative dans la réalisation de projets à engagement.",
            "qsn_histoire": "Notre histoire",
            "qsn_histoire_text": "Ormaes est une société fondée par un duo d’amoureux du service, des projets techniques, de l’engagement et de la bonne humeur. Nous disposons d’un état d’esprit singulier où la finalité prime sur les règles établies et où la satisfaction client au travers d’une relation partenariale forte est incarnée au quotidien. Notre métier est l’intégration de systèmes, l’architecture, l’expertise technique avec une approche par projet.",
            "qsn_localisation": "Nous sommes fièrement localisés à Strasbourg,  en",
            "qsn_equipe": "L'équipe Ormaes",
            "qsn_burger1": "Directeur Technique",
            "qsn_burger2": "Co-fondateur",
            "qsn_jolin1": "Directeur Commercial",
            "qsn_jolin2": "Co-fondateur",
            "qsn_klein1": "Expert Technique",
            "qsn_klein2": "Java Python R&D",
            "qsn_talbot1": "Expert Technique",
            "qsn_talbot2": "Bilingue C# Java",
            "qsn_boudon1": "Ingénieure Centrale Lille",
            "qsn_boudon2": "Java ReactJS Flutter",
            "qsn_buresi1": "Ingénieur INSA Lyon",
            "qsn_buresi2": "Expert SwiftUI, Flutter et Java",

            "qsn_valeurs": "Nos valeurs",
            "qsn_professionalisme": "Le professionalisme",
            "qsn_professionalisme_text": " De la manière dont nous abordons nos projets jusqu'à notre communication avec nos clients, nous nous efforçons toujours d'agir avec rigueur, expertise et intégrité.",
            "qsn_entrepreunariat": "L'entrepreneuriat",
            "qsn_entrepreunariat_text": "Nous encourageons nos équipes à chercher constamment des opportunités d'amélioration, à repousser nos limites et à explorer de nouvelles voies pour atteindre nos objectifs.",
            "qsn_cafetiere": "La cafetière",
            "qsn_cafetiere_text": "Nous croyons en l'importance de créer un environnement propice à la productivité, et une bonne tasse de café fait partie intégrante de notre routine quotidienne.",
            "qsn_vie": "La vie chez Ormaes",
            "qsn_vie_text1": "Nous aimons notre travail, nous sommes experts en génie logiciel et en intégration et nous sommes tous complémentaires. Nous aimons la technique et considérons que tout problème a une solution. Pour autant, faire les bonnes choses, bien les faire, à un coût concurrentiel et dans les délais convenus, cela implique de nombreux challenges.",
            "qsn_vie_text2": "Nous avons à cœur d’être fiers de notre travail. Nous développons notre activité avec des clients pour lesquels nous serons un partenaire. Nous cherchons en permanence à apprendre de nouvelles choses. Nous impliquons l’équipe dans les décisions importantes de l’entreprise. Nous croyons à une hiérarchie horizontale centrée sur la collaboration et qui encourage l’équipe à travailler ensemble sur un pied d’égalité pour atteindre les objectifs de l’entreprise.",
            "qsn_vie_text3": "Notre métier est difficile et exigeant. L’efficacité opérationnelle passe par d’excellentes relations interpersonnelles et par le sentiment d’appartenance à l’équipe et au projet de l’entreprise. Nous portons une attention toute particulière à la qualité de vie au travail et impliquons l’équipe dans un plan d’amélioration continu. Nous souhaitons incarner au quotidien notre promesse : Nous sommes uniques, particuliers et collectivement Ormaes.",
            "qsn_en_cours1": "Page en cours d'élaboration",
            "qsn_en_cours2": "En savoir plus",
            "qsn_confiance": "Ils nous font déjà confiance",
            "qsn_confiance_text": "Chez Ormaes, nous sommes fiers de la confiance que nous accordent nos clients et partenaires. Grâce à notre expertise technique et à notre approche axée sur la satisfaction client, nous avons établi des relations solides avec de nombreuses entreprises et organisations de divers secteurs.",
            "qsn_decouvrir1": "Découvrir notre",
            "qsn_decouvrir2": "offre",
            "qsn_decouvrir3": "Notre offre",

//Notre offre
            "offre_savoir": "Notre savoir faire",
            "offre_title": "Ormaes | Notre offre",
            "offre_projet1": "On démarre ",
            "offre_projet2": "votre projet ?",
 
//Services
            "service_nos": "Nos ",
            "service_services": "services",
            "service_expertise": "Expertise",
            "service_expertise_text": "Architecture, Urbanisme SI, Expertise Technologique et outils (middleware, IDE) et Frameworks",
            "service_developpement": "Développement d'applications",
            "service_developpement_text": "Maîtrise d’oeuvre en mode projet MCO - Build - MVP, Expertise front et back CSS",
            "service_integration": "Intégration",
            "service_integration_text": "Intégration de SI, Continuous Integration, Webservices, scalabilité, résilience SI",
            "service_decouvrir": "Découvrir",

//Contact
            "contact_projet1": "Vous avez",
            "contact_projet2": "un projet ?",
            "contact_collaborer1": "Vous souhaitez",
            "contact_collaborer2": "collaborer ?",
            "contact_voir1": "Vous pouvez aussi passer",
            "contact_voir2": "nous voir",
            "contact_contacter": "Contactez-vous",

//NousRejoindre
            "rejoindre_rejoindre1": "Nous",
            "rejoindre_rejoindre2": "Rejoindre",
            "rejoindre_text1": "Ormaes renforce l’équipe ! Nous cherchons des co-équipiers polyvalents qui sauront intégrer les équipes projets. Ils interviendront sur tout le cycle des projet.",
            "rejoindre_text2": "Pour ce faire, les compétences suivantes renforceront les capacités de la société selon la visibilité conférée par les projets :",
            "rejoindre_li1": "Ingénieur d’études et de développement JAVA, C#, Swift (IOS), Android, flutter, JavaScript (Angular, React, vue)",
            "rejoindre_li2": "Testeur impliquant des compétences d’automatisation",
            "rejoindre_li3": "Analyste d’affaire / Product Owner",
            "rejoindre_li4": "Chef de projet technique",
            "rejoindre_plus": "En savoir plus",
            
//Home
            "home_home": "Ormaes | Accueil",

//Carrières
            "carrieres_title": "Ormaes | Nous Rejoindre",
            "carrieres_rejoindre": "Rejoindre Ormaes",
            "carrieres_text1": "Rejoindre Ormaes permet de travailler sur des projets variés ou la technique est au service du métier. Vous collaborez avec des professionnels expérimentés et développez vos compétences techniques sur un spectre large. Nous sommes attachés à l'épanouissement personnel et professionnel de nos collaborateurs, en leur offrant un environnement de travail stimulant dans un cadre que nous avons souhaité très agréable.",
            "carrieres_efficaces" : "Nous sommes efficaces",
            "carrieres_efficaces_text" : "Grâce à notre organisation et méthode de travail, nous sommes en mesure de livrer des résultats de qualité dans les délais impartis. Nos 4 leviers de productivité : périmètre, qualité, coût, délais.",
            "carrieres_entreprenants" : "Nous sommes entreprenants",
            "carrieres_entreprenants_text" : "Nous encourageons chacun des co-équipiers à exprimer leur créativité et analyse critique à travers le partage bienveillant de toute idée d'amélioration permettant d'aller au-delà de la simple exécution du travail demandé. Nous nous efforçons à créer de la valeur sur tout le spectre de nos interventions.",
            "carrieres_efficients" : "Nous sommes efficients",
            "carrieres_efficients_text" : "Comme tout bon artisan, la maîtrise de notre métier, issu de nos expériences et savoir-faire, nous permet d'aller rapidement à l'essentiel et de produire un travail de qualité et dont le résultat correspond aux attentes de nos clients.",
           
            "carrieres_profils" : "Les profils recherchés",
            "carrieres_passionne" : "Passionné",
            "carrieres_passionne_text" : "Avoir cette étincelle intérieure qui vous pousse à vous plonger corps et âme dans votre travail.",
            "carrieres_curieux" : "Curieux",
            "carrieres_curieux_text" : "Avoir une envie constante d'apprendre. Etre ouvert et motivé à l'idée de travailler dans différents contextes métiers et techniques.",
            "carrieres_talentueux" : "Talentueux",
            "carrieres_talentueux_text" : "Posséder un talent exceptionnel qui vous distingue et vous permet d'exceller dans votre domaine.",
            "carrieres_creatif" : "Créatif",
            "carrieres_creatif_text" : "Exprimer créativité et sens critique via partage bienveillant de toute idée d'amélioration pour dépasser simple exécution travail demandé.",
            "carrieres_offres" : "Les offres disponibles",
            
            "carrieres_offres_java" : "Concepteur Développeur Java et Javascript",
            "carrieres_offres_java_type" : "tech",
           
            "carrieres_offres_java_contrat" : "CDI",
            "carrieres_offres_java_lieu" : "Strasbourg",
            "carrieres_offres_java_mission1" : "Participer à la conception technique, au développement et aux tests tout comme à l’intégration au SI.",
            "carrieres_offres_java_mission2" : "Participer aux livraisons et déploiements.",
            "carrieres_offres_java_mission3" : "Résoudre les problèmes techniques et fonctionnels sur l’ensemble du périmètre.",
            "carrieres_offres_java_mission4" : "Contribuer à l'évolution des méthodes, outils, pratiques avec l’équipe.",
            "carrieres_offres_java_mission5" : "Appréhender les risques et les problèmes et trouver des solutions pour les contenir et/ou les anticiper.",
            "carrieres_offres_java_mission6" : "Rédiger de la documentation.",
            "carrieres_offres_java_mission7" : "Assurer le respect des bonnes pratiques et des normes de développement.",
            "carrieres_offres_java_mission8" : "Faire de la veille technologique (frameworks, outils, méthodologies, architectures).",
            "carrieres_offres_java_profil" : "De formation Informatique et titulaire d’une expérience tangible (plus de une année), vous êtes reconnu pour votre capacité à apprendre et votre implication sur les sujets qui vous sont confiés. Vous êtes autonome et savez solliciter de l’aide lorsque cela est nécessaire. Votre curiosité, votre affection pour la technique et capacité de travailler en équipe est primordiale. Vous êtes doté d'un excellent sens relationnel et aimez partager vos connaissances. Votre rigueur, pugnacité, capacité d'adaptation et soif de challenge vous permettront de bien appréhender les missions qui vous seront confiées. Vous avez acquis une compétence technique solide dans le cadre de projets nouvelles technologies et vous avez au moins une première expérience professionnelle dans un contexte projet. Vous êtes passionné par la technique et maîtrisez un ou plusieurs des environnements techniques.",
            "carrieres_offres_java_requis1" : "Maîtrise de la programmation orientée objet et des langages Java et Javascript.",
            "carrieres_offres_java_requis2" : "Maîtrise de Frameworks Java (JSF, Spring, Hibernate, ...).",
            "carrieres_offres_java_requis3" : "La connaissance de framework Javascript pour le Frontend (type AngularJS, Node.js, React.js, Vue.js).",
            "carrieres_offres_java_requis4" : "Maîtrise du langage SQL et des bases de données.",
            "carrieres_offres_java_requis5" : "Maîtrise des méthodes de conception, des outils de développement (Eclipse ou IntelliJ) et de tests (jUnit, Sonar, …), de versionning (Gitlab CI/CD, GitKraken) et de déploiement (Jenkins,Ansible, Maven).",
            "carrieres_offres_java_souhait1" : "Back-end SpringBoot Reactive, Spring, WebServices REST, SpringBatch.",
            "carrieres_offres_java_souhait2" : "Connaissance de JIRA.",

            "carrieres_offres_mobile" : "Développeur / Concepteur Mobile / Web",
            "carrieres_offres_mobile_type" : "tech",
            "carrieres_offres_mobile_contrat" : "CDI",
            "carrieres_offres_mobile_lieu" : "Strasbourg",

            "carrieres_offres_mobile_mission1" : "Participer à la conception technique, au développement et aux tests tout comme à l’intégration au SI.",
            "carrieres_offres_mobile_mission2" : "Participer aux livraisons et déploiements.",
            "carrieres_offres_mobile_mission3" : "Résoudre les problèmes techniques et fonctionnels sur l’ensemble du périmètre.",
            "carrieres_offres_mobile_mission4" : "Contribuer à l'évolution des méthodes, outils, pratiques avec l’équipe.",
            "carrieres_offres_mobile_mission5" : "Soutenir les pairs.",
            "carrieres_offres_mobile_mission6" : "Appréhender les risques et les problèmes et trouver des solutions pour les contenir et/ou les anticiper.",
            "carrieres_offres_mobile_mission7" : "Contribuer à la production de l’ensemble des livrables.",
            "carrieres_offres_mobile_mission8" : "Rédiger de la documentation.",
            "carrieres_offres_mobile_mission9" :"Assurer le respect des bonnes pratiques et des normes de développement.",
            "carrieres_offres_mobile_mission10" :"Travailler avec les membres de l'équipe dans un esprit collaboratif et constructif.",
            "carrieres_offres_mobile_mission11" :"Participer aux revues du code.",
            "carrieres_offres_mobile_mission12" :"Faire de la veille technologique (frameworks, outils, méthodologies, architectures).",
            "carrieres_offres_mobile_mission13" :"Suivre l’avancement et ajuster le reste à faire.",
            "carrieres_offres_mobile_mission14" :"Faire preuve d’initiative, d’implication forte, de respect des autres et d’esprit d’équipe permettant à tous de travailler dans un environnement et climat de confiance et de responsabilités partagées.",

            "carrieres_offres_mobile_profil" : "De formation Informatique et titulaire d’une expérience tangible, vous êtes reconnu pour votre capacité à apprendre et votre implication sur les sujets qui vous sont confiés. Vous êtes autonome et savez solliciter de l’aide lorsque cela est nécessaire. Votre curiosité, votre affection pour la technique et capacité de travailler en équipe est primordiale. Vous êtes doté(e) d'un excellent sens relationnel et aimez partager vos connaissances. Votre rigueur, pugnacité, capacité d'adaptation et soif de challenge vous permettront de bien appréhender les missions qui vous seront confiées.",
            "carrieres_offres_mobile_requis1" : "Développement mobile : Android ou  iOS.",
            "carrieres_offres_mobile_requis2" : "Environnement DevOps : Gitlab CI/CD, projet en cours de conteneurisation de l’infrastructure.",
            "carrieres_offres_mobile_requis3" : "Environnement de développement : à la discrétion du développeur (Mac / Windows / Linux), Choix libre de l’IDE, licences achetées si besoin par Ormaes (Intellij, GitKraken ou autre).",
            
            "carrieres_offres_mobile_souhait1" : "Flutter.",
            "carrieres_offres_mobile_souhait2" : "Module de paiement, NFC, Maps.",
            "carrieres_offres_mobile_souhait3" : "WEB: Javascript Vanilla, Angular 14.",
            "carrieres_offres_mobile_souhait4" : "Back-end SpringBoot Reactive, Spring, WebServices REST, RabbitMQ, SpringBatch.",
            "carrieres_offres_mobile_souhait5" : "Base de données : MongoDB, MariaDB.",
            "carrieres_offres_mobile_souhait6" : "Méthode Agile adaptée au contexte (JIRA Agile).",
//OffredeJob            
            "offredejob_mission" : "Vos missions:",
            "offredejob_profil" : "Profil recherché:",
            "offredejob_environnement" : "Environnement technique:",
            "offredejob_requis" : "Requis :",
            "offredejob_souhaite" : "Souhaité :",
            "offredejob_postuler" : "Postuler",
            
//Contact->Form
            "form_cv" : "Ton CV est obligatoire, trouve un moyen de nous le transmettre 👀  ",
            "form_message" :"Envoie-nous un message 🚀",
            "form_nom" :"Nom et prénom*",
            "form_email" :"Adresse e-mail*",
            "form_phone" :"Téléphone",
            "form_value1" :"Prestation / Services",
            "form_value2" :"Recrutement",
            "form_value3" :"Autre",
            "form_parle" :"Parle-nous de ton projet*",
            "form_envoyer" :"Envoyer",
            "form_drag" :" ou drag & drop",    
            "form_upload" :"Clique pour télécharger",    
            "form_pdf" :"PDF, JPG, JPEG",  

 //Contact->Form->Snackbar
 "form_snackbar_success" : "Courriel envoyé avec succès",
 "form_snackbar_fail" : "Echec d'envoi de courriel",


     

//Contact
            "contact_parlons" :"Parlons de vos projets",
            "contact_qui" :"Mais avant, dis-nous qui tu es :)",
            "contact_title" :"Ormaes | Contact",
            "contact_creons" :"Créons quelque chose ensemble🤘",
            "contact_ecrire" :"Nous écrire par mail à",
            "contact_mail" :"contact@ormaes.fr",
            "contact_carte" :"Voir la carte",

// Contact_MailEnvoyé
            "mailenvoye" : "Merci de nous avoir contacté !",

//Contact_MailNonEnvoyé
            "mailnonenvoye" : "Il faut que tu attendes un peu avant envoyer un mail",

//Contact_Spam
            "spam_impossible" : "Impossible d'envoyer plus d'un mail toutes les 30 secondes",
            "spam_back" : "Revenir à l'accueil en cliquant sur le boutton ci-dessous.",
            "spam_accueil" : "Accueil",

//Contact_ValidationMessage
            "validationmessage_succes" : "Le message à été envoyé avec succès !",
            "validationmessage_back" : "Revenir à l'accueil en cliquant sur le boutton ci-dessous.",
            "validationmessage_accueil" : "Accueil",

//LaVieChezOrmaes -> Page pas encore activée, contenu pas encore traduit

// MentionsLégales
            "mentions_mentions" : "Mentions légales",
            "mentions_editeurs" : "Editeurs du site :",
            "mentions_site" : "Site internet ORMAES édité par ORMAES ",
            "mentions_ormaes" : "http://www.ormaes.fr",
            "mentions_sas" : "SAS au capital de 4 000 € ",
            "mentions_adresse1" : "79a rue de la plaine des bouchers ",
            "mentions_adresse2" : "67100 Strasbourg ",
            "mentions_rcs" : "Enregistrée au RCS de Strasbourg sous le numéro 920 990 017 ",
            "mentions_tva" : "Numéro de TVA intracommunautaire : FR44 920 990 017",
            "mentions_representants" : "Représentants légaux :",
            "mentions_laurenteric" : "Eric JOLIN, Laurent BURGER ",
            "mentions_contact" : "Contact",
            "mentions_siege" : "Siège social : ",
            "mentions_adresse3" : "79a rue de la plaine des bouchers, 67100 Strasbourg, France  ",
            "mentions_telephone" : "Joignable au +33 (0)3 88 61 02 08",
            "mentions_courriel" : "Par courriel : contact@ormaes.fr ",
            "mentions_dirpub" : "Directeurs de la publication : ",
            "mentions_ericlaurent" : "Eric JOLIN, Laurent BURGER",
            "mentions_cgu" : "Conditions générales d’utilisation ",
            "mentions_siteaccessible" : "Le site internet est accessible par les adresses suivantes : ",

            "mentions_ormaes2" : "www.ormaes.fr",
            "mentions_cgufraccept" : "Il est exploité dans le respect de la législation française. L’utilisation de ce site est régie par les présentes conditions générales d’utilisation. En utilisant le site, vous reconnaissez avoir pris connaissance de ces conditions et les avoir acceptées. Elles pourront être modifiées à tout moment et sans préavis. Ormaes SAS ne saurait être tenu pour responsable d’une mauvaise utilisation du service.",
            "mentions_hebergeur" : "Hebergeur du site ",
            "mentions_ovh" : "OVH ",
            "mentions_ovhsiege" : "Siège social : 2 rue Kellermann - 59100 Roubaix - France.",
            "mentions_ovhadresse" : "OVH SAS est une filiale de la société OVH Groupe SA, société immatriculée au RCS de Lille sous le numéro 537 407 926 sise 2, rue Kellermann, 59100 Roubaix.",
            "mentions_limiteresp" : "Limites de responsabilité ",
            "mentions_maj" : "Les informations contenues sur le site internet sont aussi précises que possible. Il est mis à jour périodiquement, mais peut toutefois contenir des inexactitudes, des omissions, des lacunes ou informations n’étant plus à jour. Si vous constatez une omission ou erreur ou plus généralement ce qui parait être un dysfonctionnement, nous vous remercions de bien vouloir nous le signaler par courriel en décrivant le problème de la manière la plus précise possible afin que nous puissions traiter l’objet de votre demande dans les meilleurs délais. ",
            "mentions_risques" : "Tout contenu téléchargé se fait aux risques et périls de l’utilisateur et sous sa seule responsabilité. En conséquence, ORMAES ne saurait être tenu responsable d’un quelconque dommage subi par l’ordinateur de l’utilisateur ou d’une quelconque perte de données consécutives au téléchargement.",
            "mentions_images" : "Les images ne sont pas contractuelles. ",
            "mentions_liens" : "Les liens externes présents sur le site internet en direction d’autres ressources sur le réseau Internet ne sauraient engager la responsabilité d’Ormaes.",
            "mentions_litiges" : "Litiges, droit applicable et tribunal compétent ",
            "mentions_litiges_text" : "Le présent site et conditions d’utilisations sont régis par le droit français. Toute contestation ou litige relèvera des compétence exclusive des tribunaux dont dépend le siège social d’Ormaes.",
            "mentions_pi" : "Propriété intellectuelle ",
            "mentions_pi_donnees" : "La structure du site, et de façons non limitative, les contributions, images, textes, vidéos, sons, graphismes, icônes, documents téléchargeables, bases de données, services web et tout autre élément composant le site sont la propriété exclusive d’ORMAES, à l’exception les marques, images et logos des entreprises partenaires, clientes, ou auteures qui sont la propriété exclusive des sociétés qu’ils représentent.",
            "mentions_pi_fr" : "L’ensemble de ce site relève de la législation française en ce qui concerne les droits d’auteurs et de la propriété intellectuelle.",
            "mentions_pi_repro" : "Toute utilisation, reproduction totale ou partielle, du site et des éléments qui le composent par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par le Code de la propriété intellectuelle. Le non-respect de cette interdiction constitue une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur.",
            "mentions_confid" : "Confidentialité",
            "mentions_confid_text" : "Tous nos services en ligne limitent la collecte des données personnelles au strict nécessaire (minimisation des données).  Vos données personnelles sont confidentielles et ne sont pas communiquées à des tiers. Les données recueillies sur notre site internet ne sont pas vendues à des tiers. ",
            "mentions_pol_cookies" : "Politique en matière de cookies",
            "mentions_cookie_def" : "Qu’est-ce qu’un cookie ",
            "mentions_cookie_def_text" : "Un cookie est un petit fichier constitué de lettres et de chiffres et téléchargé sur votre ordinateur lorsque vous accédez à certains sites web. En général, les cookies permettent à un site web de stocker sur votre ordinateur des données techniques de navigation tel que l’identification de l’ordinateur de l’utilisateur.",
            "mentions_cookie_pourquoi" : "​Pourquoi nous utilisons des cookies",
            "mentions_cookie_expl" : "Nous pouvons utiliser des cookies et d’autres technologies similaires pour un certain nombre de raisons, par exemple : i) pour des besoins de sécurité ou de protection contre la fraude, et afin d’identifier et de prévenir les cyber-attaques, ii) pour des besoins de statistiques et d’affichage iii) pour contrôler et analyser les performances, le fonctionnement et l’efficacité de notre service et iv) pour en améliorer l’expérience utilisateur. ",
            "mentions_cookie_disable" : "Il est possible d’empêcher votre navigateur d’accepter les cookies en modifiant les paramètres concernés dans votre navigateur.",
            "mentions_cookie_suppr" : "Veuillez noter que la suppression de nos cookies ou la désactivation de futurs cookies ou technologies de suivi pourront vous empêcher d’accéder à certaines zones ou fonctionnalités de nos services, ou pourront autrement affecter négativement votre expérience d’utilisateur. ",
            "mentions_cookie_politique" : "Nous nous réservons la faculté de faire évoluer notre politique en matière de cookies. Nous vous encourageons à consulter régulièrement cette page pour obtenir les dernières informations sur les cookies.",
            "mentions_remerciements" : "Remerciements",
            "mentions_bonne_nav" : "Enfin nous vous souhaitons surtout une bonne navigation et sommes très heureux que vous ayez eu la ténacité de lire l’ensemble de nos CGU & mentions légales, ce qui est digne de mention. Nous demeurons à votre disposition dans la vie réelle. Le digital c’est super, la vraie vie c’est encore mieux. ",

//Teapot
            "teapot_iam" : "I'm a TEAPOT",
            "teapot_418" : "Code d'erreur : 418",
            "teapot_alt" : "Théière",
            "teapot_attention" : "Attention, vous avez activé le mode théière ! Préparez-vous à une infusion de folie et d'humour bouillant",
            "teapot_accueil" : "Accueil",

//Error404
            "error_oups" : "Oups !",
            "error_encours_dev" : "La page que vous recherchez est en cours de développement !",
            "error_code_erreur" : "Code d'erreur : 404",
            "error_accueil" : "Accueil",

// NotreOffre -> Accordion.js
// 01. Développement sur mesure
            "notreoffre_01_titre" : "01. Développement d’applications sur mesure",
            "notreoffre_01_paragraphe1" : "Votre métier est particulier, vos exigences fonctionnelles et techniques le sont aussi ou tout simplement, il n’existe pas sur le marché une application qui correspond à vos besoins. Nous sommes le partenaire qu’il vous faut.",
            "notreoffre_01_paragraphe2" : "Notre métier consiste à appréhender rapidement votre métier, vos particularités, votre organisation et votre SI. Nous avons mené à bien des projets dans des domaines métier très variés : billettique, monétique, systèmes de caisse en grande distribution, formation professionnelle et systèmes d’information pédagogiques, production en industrie lourde, usines connectées du secteur industriel, téléphonie fixe, optimisation de robots de délivrance de médicaments, diagnostiques en radiologie, contrôle d'irradiation de dose en utilisation de scanners, commercialisation d’électricité, … ",
            "notreoffre_01_paragraphe3" : "Le savant mélange d’un intérêt fort pour le métier et d’une passion pour les technologies, tout comme notre expérience significative des projets complexes vous apporteront une confiance importante dans notre capacité d’adaptation à vos enjeux et plus particulièrement d'une maîtrise de votre projet. ",
            "notreoffre_01_paragraphe4" : "Notre force réside dans notre capacité à intégrer beaucoup de complexité inhérente à votre métier, à votre système d’information ou  lorsque plusieurs SI/applications doivent communiquer entre eux.",
            "notreoffre_01_paragraphe5" : "Notre objectif : Savoir rendre simple pour vous ce qui est compliqué.",
            "notreoffre_01_paragraphe6" : "Nous constituons une équipe pluridisciplinaire et complémentaire couvrant les métiers intervenant dans le cycle des projets informatiques :",
            "notreoffre_01_li1" : "Accompagnement amont permettant la formalisation de l’expression de besoin et des exigences",
            "notreoffre_01_li2" : "Aide au choix : make or buy",
            "notreoffre_01_li3" : "Analyse des affaires (business analysis)",
            "notreoffre_01_li4" : "Architecture logicielle et urbanisation",
            "notreoffre_01_li5" : "Pilotage de projets",
            "notreoffre_01_li6" : "Mise en place des environnements et de l'outillage : Expertise middleware et infrastructure",
            "notreoffre_01_li7" : "Développement logiciel",
            "notreoffre_01_li8" : "Intégration de la solution au système existant et aux applications amont et aval",
            "notreoffre_01_li9" : "Accompagnement à la recette (UAT)",
            "notreoffre_01_li10" : "Accompagnement au déploiement",
            "notreoffre_01_li11" : "Maintien en Condition Opérationnelle, support et maintenance de tout type en exploitation",
            "notreoffre_01_p1" : "Les types d’applications que nous concevons :",
            "notreoffre_01_li12" : "Applications Web qui sont « responsives » et « adaptatives »",
            "notreoffre_01_li13" : "Applications client lourd (desktop)",
            "notreoffre_01_li14" : "Applications de type serveur",
            "notreoffre_01_li15" : "Applications mobiles IOS et Android",
            "notreoffre_01_li16" : "Portails en utilisation de CMS",
            "notreoffre_01_paragraphe7" : "Le développement logiciel se complexifiant en permanence, nous disposons aussi d’un important réseau de partenaires permettant d’adjoindre aux projets que nous menons, des expertises pointues dans différents domaines.",

// 02. Intération de systèmes
            "notreoffre_02_titre" : "02. Intégration",
            "notreoffre_02_paragraphe1" : "L’intégration logicielle englobe 2 facettes principales qui nécessitent chacunes des compétences particulières :",
            "notreoffre_02_paragraphe2" : "L’intégration de Frameworks ou de bibliothèques spécifiques :",
            "notreoffre_02_li1" : "Il s’agit ici de profiter de composants déjà réalisés afin d’accélérer les développements spécifiques, permettant de se concentrer sur la création de valeur ajoutée métier dans le projet,",
            "notreoffre_02_li2" : "Cette intégration nécessite une expertise technique de développement, et une capacité à prendre de la hauteur pour choisir les composants à intégrer en s’assurant de conserver un projet cohérent, efficace et pérenne,",
            "notreoffre_02_li3" : "L’intégrateur de framework fait partie à l’équipe de développement, il est souvent technical leader.",
            "notreoffre_02_paragraphe3" : "L’intégration de solutions :",
            "notreoffre_02_li4" : "Il s’agit ici d’intégrer des solutions du marché (propriétaires comme open source) dans un système d’information existant,",
            "notreoffre_02_li5" : "L’intégration de solutions ne nécessite pas de connaître les solutions à intégrer, mais demande d’avoir une bonne capacité d’analyse, des compétences en architecture et urbanisation et de l’expérience en analyse de solution et en étude d’impact,",
            "notreoffre_02_li6" : "L’intégrateur de solutions se doit d’avoir l’esprit d’équipe, de faire preuve d’une grande adaptabilité et d’une ouverture d’esprit, afin d’être en capacité d’intégrer tout type de solutions,",
            "notreoffre_02_li7" : "L’intégrateur de solution est intégré à l’équipe d’architecture et/ou à l’équipe infrastructure. Il est capable d'élaborer une liste de tâches à réaliser pour l’intégration, afin de la confier à l’équipe de développement ou de réaliser ces tâches lui-même.",

// 03. Expertises Techniques
            "notreoffre_03_titre" : "03. Expertises Techniques",
            "notreoffre_03_paragraphe1" : "Nous disposons d'une d'expertise forte en développement d'applications sur mesure en mode projet et en intégration. Ceci emporte l'usage intensif de méthodes, d'outils, de framework et langages sur lesquels nous avons nous avons développé de l'expertise. Nous intervenons régulièrement, sur demande, en expertise, sur des prestations faisant appel à ces savoir-faire spécifiques:",
            "notreoffre_03_li1" : "Estimation et chiffrage des coûts projet par abaques",
            "notreoffre_03_li2" : "Architecture logicielle et urbanisation de systèmes d'information: Aide au choix technologiques, réduction de la dette technique, migration technique (exemple: java 9 vers Java 19 ou Spring 3 vers Spring 6)",
            "notreoffre_03_li3" : "Migrations techniques",
            "notreoffre_03_li4" : "Modernisation de systèmes d'information",
            "notreoffre_03_li5" : "Configuration complète, paramétrage et configuration de flux de travail  avec la solution Jira d'Atlassian. Reprise de backlog depuis une solution tierce.",
            "notreoffre_03_li6" : "Java Avancé, Spring, JSF",
            "notreoffre_03_li7" : "Android (Kotlin), IOS (Swift), Dart / Flutter ou encore ReactNative",
            "notreoffre_03_li8" : "Angular, NodeJS, ReactJS ",
            "notreoffre_03_li9" : "C#, ASP.NET, .NET Framework",
            "notreoffre_03_li10" : "C++",
            "notreoffre_03_li11" : "Python",
            "notreoffre_03_li12" : "Talend ETL",
            "notreoffre_03_li13" : "OpenAPI",
            "notreoffre_03_li14" : "Liferay 6.2 à la version 7.3 (DXP), OpenCMS (Alcakon)",
            "notreoffre_03_li15" : "Qualité du code et Tests Unitaires: JUnit, NUnit, Mockito, Winium, Selenium, SonarCube, Lint",
            "notreoffre_03_li16" : "SGBD / SQL / NOSQL / PLSQL / ORM: Réalisation, analyse et optimisation de requêtes (MySQL, Postgresql, Oracle, Firebird, SQL Server, MariaDB). Mise en place de Cluster/Replicaset et réalisation de requêtes complexes avec MongoDB",
            "notreoffre_03_li17" : "Tests de performance : stratégie de tests, mise en place d'environnements, tests de charge, aux limites, de robustesse, JMeter, Loadrunner, Taurus",
            "notreoffre_03_li18" : "Chaîne CI/CD",
            "notreoffre_03_li19" : "Reconnaissance et lecture automatique de documents (RAD/LAD) : Tesseract, OpenCV, ABBYY",
            "notreoffre_03_paragraphe2" : "Bien que nous aimions Prévert, nous nous limitons à nos expertises principales et arrêtons la liste ici.",

// Footer
            "footer_accueil" : "Accueil",
            "footer_qui" : "Qui sommes-nous",
            "footer_offre" : "Notre offre",          
            "footer_carrieres" : "Carrières",          
            "footer_contact" : "Contact",
            "footer_mentions" : "Mentions légales", 
            "footer_droits" : "2024 Ormaes. Tous droits réservés.", 
            
//MenuDrawer
"menudrawer_item1" : "Métier",            
"menudrawer_subitem1.1" : "Développement d'applications",
"menudrawer_subitem1.2" : "Expertises Techniques",
"menudrawer_subitem1.3" : "Expertises Métier",
"menudrawer_item2" : "Écosystème",       
"menudrawer_item3" : "Études de cas",       
"menudrawer_item4" : "Carrières", 
"menudrawer_retour" : "Retour au menu principal",       
"menudrawer_demarrer_projet" : "Démarrer un projet",  

//nodemailer ??? 
       

//          {t("footer_accueil")}


        }
          },
    en: {
        translation: {
// Landing
            "accueil_header": "<h1 class=\"text-3xl font-bold text-center mb-2 md:text-4xl xl:text-left xl:text-4xl text-gray-800\">" +
                "               <span class=\"text-primOrange\">Software engineering</span> and <span class=\"text-primOrange\">Consulting</span> service provider</h1>",
            "landing_content": "Partner of your digital projects, ORMAES deploys a methodology that combine technical mastery, a high level of quality of service and the well being of the Team.",
            "accueil_trust": "They trust us",
            "accueil_parler_projet": "LET'S TALK ABOUT YOUR PROJECT",
            "accueil_confiance": "They trust us",
            "accueil_developpement": "Software Engineering",
            "accueil_integration": "Systems Integration",
            "accueil_expertise": "Expertise",
            
// Header
            "header_accueil": "Home",
            "header_qui": "Who are we ?",
            "header_offers": "Our offer",
            "header_career": "Careers",
            "header_contact": "Contact",

//About
            "about_qui": "Who ",  
            "about_sommes-nous": "are we", 
            "about_ormaes_1": "Ormaes is a company founded by a duo of software engineering service delivery, technical projects, commitment and good mood aficionados. We are passionate about technical projects and commitment. Always in a good mood. We believe in a unique mindset where purpose supercedes established rules and where customer satisfaction through a strong partnership relationship is embodied on a daily basis.",  
            "about_ormaes_2": "We consider ourselves as demanding, responsible and empathetic. We like to empower our staff and accordingly we give our trust. We recognize work that is well done, effort and involvement. We also know when to recognize our mistakes, help grow professionally and give meaning to what we how. We love our work and are proud of what we accomplish.", 
            "about_ormaes_decouvrir": "Get to know us",
            
//QuiSommesNous
            "qsn_who": "Who are we ?",
            "qsn_apprenez": "Get to know us",
            "qsn_title": "Ormaes | Who are we ?",
            "qsn_confiance1": "Here's something to inspire you trust.",
            "qsn_about": "About us",
            "qsn_about_text": "Ormaes is an IT service provider company (SAS) founded in November 2022 by 2 co-founding partners: Eric JOLIN and Laurent BURGER. They have significant experience in carrying out projects engaging strong commitment.",            "qsn_histoire": "Our story",
            "qsn_histoire_text": "Ormaes is a company founded by a duo of software engineering service delivery, technical projects, commitment and good mood aficionados. We are passionate about technical projects and commitment. Always in a good mood. We believe in a unique mindset where purpose supercedes established rules and where customer satisfaction through a strong partnership relationship is embodied on a daily basis. Our business is systems integration, architecture, technical expertise with a project approach.",
            "qsn_localisation": "We are proudly located in",
            "qsn_equipe": "The Ormaes team",
            "qsn_burger1": "Technical Director",
            "qsn_burger2": "Cofounder",
            "qsn_jolin1": "Sales Director",
            "qsn_jolin2": "Cofounder",
            "qsn_klein1": "Technical Expert",
            "qsn_klein2": "Java Python R&D",
            "qsn_talbot1": "Technical Expert",
            "qsn_talbot2": "Bilingual C# Java",
            "qsn_boudon1": "Software Engineer",
            "qsn_boudon2": "Java ReactJS Flutter",
            "qsn_buresi1": "INSA Lyon Software Engineer",
            "qsn_buresi2": "SwiftUI, Flutter and Java Expert",
            "qsn_valeurs": "Our values",
            "qsn_professionalisme": "Professionalism",
            "qsn_professionalisme_text": "From the way we approach our projects to our communication with our clients, we always strive to act with rigor, expertise and integrity.",
            "qsn_entrepreunariat": "Entrepreneurship",
            "qsn_entrepreunariat_text": "We encourage our teams to constantly look for opportunities to improve, push our limits and explore new paths to achieve our goals.",
            "qsn_cafetiere": "The coffee machine",
            "qsn_cafetiere_text": "We believe in creating an environment conducive to productivity, and a good cup of coffee is an integral part of our daily routine.",
            "qsn_vie": "Life at Ormaes",
            "qsn_vie_text1": "We love our work, we are experts in software engineering and systems integration and we all complement each other. We love technology and believe that every problem has a solution. However, doing the right things, doing them well, at a competitive cost and within the agreed deadlines, involves many challenges.",
            "qsn_vie_text2": "We are committed to being proud of our work. We develop our business with clients for whom we will be a partner. We are always looking to learn new things. We involve the team in important company decisions. We believe in a flat hierarchy centered on collaboration and which encourages the team to work together as equals to achieve the company's objectives.",
            "qsn_vie_text3": "Our job is difficult and demanding. Operational efficiency requires excellent interpersonal relationships and a feeling of belonging to the team and the company project. We pay particular attention to the quality of life at work and involve the team in a continuous improvement plan. We wish to embody our promise on a daily basis: We are unique, special and collectively Ormaes.",
            "qsn_en_cours1": "Page in progress",
            "qsn_en_cours2": "Learn more",
            "qsn_confiance": "They already trust us",
            "qsn_confiance_text": "At Ormaes, we are proud of the trust placed in us by our customers and partners. With our technical expertise and customer satisfaction driven approach, we have established strong relationships with many companies and organizations across various industries.",
            "qsn_decouvrir1": "Discover our",
            "qsn_decouvrir2": "offers",
            "qsn_decouvrir3": "Our offers",

//Notre offre
            "offre_savoir": "Our expertise",
            "offre_title": "Ormaes | Our expertise",
            "offre_projet1": "We start ",
            "offre_projet2": "your project ?",

//Services
            "service_nos": "Our ",
            "service_services": "services",
            "service_expertise": "Technical Expertise",
            "service_expertise_text": "Architecture, Information System Urbanization, Technological Expertise and tools (middleware, IDE) and Frameworks",
            "service_developpement": "Software Development",
            "service_developpement_text": "Custom build information systems in project mode, Application maintenance outsourcing - MVP, POC mode - full-stack developement, CSS expertise",
            "service_integration": "Systems Integration",
            "service_integration_text": "Systems integration, Continuous integration, Webservices, scalability, software resilience",
            "service_decouvrir": "Discover",

//Contact
            "contact_projet1": "You have",
            "contact_projet2": "a project ?",
            "contact_collaborer1": "You wish",
            "contact_collaborer2": "to collaborate ?",
            "contact_voir1": "You can also ",
            "contact_voir2": "visit us",
            "contact_contacter": "Contact us",            

//NousRejoindre
            "rejoindre_rejoindre1": "Join",
            "rejoindre_rejoindre2": "the Team",
            "rejoindre_text1": "Ormaes strengthens the team! We are looking for resourceful teammates who will be able to integrate into project teams. They will be involved throughout the project lifecycle.",
            "rejoindre_text2": "To do this, the following skills will strengthen the company's capabilities according to the visibility provided by the projects:",
            "rejoindre_li1": "Software engineer JAVA, C#, Swift (IOS), Android, flutter, JavaScript (Angular, React, vue)",
            "rejoindre_li2": "Tester involving automation skills",
            "rejoindre_li3": "Business Analyst / Product Owner",
            "rejoindre_li4": "Technical Project Manager",
            "rejoindre_plus": "Learn more",

//Home
            "home_home": "Ormaes | Home",

//Carrières
            "carrieres_title": "Ormaes | Join the team",
            "carrieres_rejoindre": "Join the Team",
            "carrieres_text1": "Joining Ormaes allows you to work on different projects where technique serves business. You collaborate with experienced professionals and develop your technical skills on a broad spectrum. We are committed to the personal and professional development of our employees, by offering them a stimulating working environment in an environment that we hope is very pleasant.",
            "carrieres_efficaces" : "We are effictive",
            "carrieres_efficaces_text" : "Thanks to our organization and working method, we are able to deliver quality results on time. Our 4 productivity levers: scope, quality, cost, deadlines.",
            "carrieres_entreprenants" : "We are entrepreneurs",
            "carrieres_entreprenants_text" : "We encourage each teammate to express his creativity and critical analysis through the kind sharing of any idea for improvement that allows him to go beyond the simple execution of the work requested. We strive to create value across the entire spectrum of our interventions.",
            "carrieres_efficients" : "We are efficient",
            "carrieres_efficients_text" : "Like any good craftsman, the mastery of our profession, resulting from our experience and know-how, allows us to quickly get to the essentials and produce quality work whose result corresponds to the expectations of our customers.",
            "carrieres_profils" : "Sought mind and skillset",
            "carrieres_passionne" : "Passionate",
            "carrieres_passionne_text" : "Have that inner spark that pushes you to immerse yourself body and soul in your work.",
            "carrieres_curieux" : "Curious",
            "carrieres_curieux_text" : "Have a constant desire to learn. Be open and motivated to the idea of ​​working in different business and technical contexts.",
            "carrieres_talentueux" : "Talented",
            "carrieres_talentueux_text" : "Possess exceptional talent that sets you apart and allows you to excel in your field.",
            "carrieres_creatif" : "Creative",
            "carrieres_creatif_text" : "Express creativity and critical thinking through the sharing of any idea for improvement to go beyond simple execution of the work requested.",
            "carrieres_offres" : "Open job positions",
            "carrieres_offres_java" : "Java / Javascript software engineer",
            "carrieres_offres_java_type" : "tech",
           
            "carrieres_offres_java_contrat" : "Permanent",
            "carrieres_offres_java_lieu" : "Strasbourg",
            "carrieres_offres_java_mission1" : "Participate in technical design, development and testing as well as integration into the Information System.",
            "carrieres_offres_java_mission2" : "Participate in deliveries and deployments.",
            "carrieres_offres_java_mission3" : "Resolve technical and functional problems across the entire scope.",
            "carrieres_offres_java_mission4" : "Contribute to the evolution of methods, tools and practices with the team.",
            "carrieres_offres_java_mission5" : "Understand risks and problems and find solutions to contain and/or anticipate them.",
            "carrieres_offres_java_mission6" : "Write documentation.",
            "carrieres_offres_java_mission7" : "Ensure compliance with good practices and development standards.",
            "carrieres_offres_java_mission8" : "Conduct technological monitoring (frameworks, tools, methodologies, architectures).",
            "carrieres_offres_java_profil" : "With an IT background and tangible experience (more than a year), you are recognized for your ability to learn and your involvement in the subjects entrusted to you. You are autonomous and know how and when to ask for help when necessary. Your curiosity, your affection for technique and the ability to work in a team is essential. You have excellent interpersonal skills and like to share your knowledge. Your rigor, pugnacity, ability to adapt and thirst for challenge will allow you to fully understand the missions that will be entrusted to you. You have acquired solid technical skills in the context of new technology projects and you have at least initial professional experience in a project context. You are passionate about technology and master one or more technical environments.",
            "carrieres_offres_java_requis1" : "Mastery of object-oriented programming and Java and Javascript languages.",
            "carrieres_offres_java_requis2" : "Mastery of Java Frameworks (JSF, Spring, Hibernate, etc.).",
            "carrieres_offres_java_requis3" : "Knowledge of Javascript framework for the Frontend (AngularJS type, Node.js, React.js, Vue.js).",
            "carrieres_offres_java_requis4" : "Mastery of SQL language and databases.",
            "carrieres_offres_java_requis5" : "Mastery of design methods, development tools (Eclipse or IntelliJ) and testing (jUnit, Sonar, etc.), versioning (Gitlab CI/CD, GitKraken) and deployment (Jenkins, Ansible, Maven).",
            "carrieres_offres_java_souhait1" : "Back-end SpringBoot Reactive, Spring, WebServices REST, SpringBatch.",
            "carrieres_offres_java_souhait2" : "Knowledge of JIRA.",
            
            "carrieres_offres_mobile" : "Mobile / Web Software Engineer",
            "carrieres_offres_mobile_type" : "tech",
            "carrieres_offres_mobile_contrat" : "Permanent",
            "carrieres_offres_mobile_lieu" : "Strasbourg",

            "carrieres_offres_mobile_mission1" : "Participate in technical design, development and testing as well as integration into the Information System.",
            "carrieres_offres_mobile_mission2" : "Participate in deliveries and deployments.",
            "carrieres_offres_mobile_mission3" : "Resolve technical and functional problems across the entire scope.",
            "carrieres_offres_mobile_mission4" : "Contribute to the evolution of methods, tools and practices with the team.",
            "carrieres_offres_mobile_mission5" : "Support peers.",
            "carrieres_offres_mobile_mission6" : "Understand risks and problems and find solutions to contain and/or anticipate them.",
            "carrieres_offres_mobile_mission7" : "Contribute to the production of all deliverables.",
            "carrieres_offres_mobile_mission8" : "Write documentation.",
            "carrieres_offres_mobile_mission9" : "Ensure compliance with good practices and development standards.",
            "carrieres_offres_mobile_mission10" : "Work with team members in a collaborative and constructive spirit.",
            "carrieres_offres_mobile_mission11" : "Participate in code reviews.",
            "carrieres_offres_mobile_mission12" : "Conduct technological monitoring (frameworks, tools, methodologies, architectures).",
            "carrieres_offres_mobile_mission13" : "Monitor progress and adjust what remains to be done.",
            "carrieres_offres_mobile_mission14" : "Demonstrate initiative, strong involvement, respect for others and team spirit allowing everyone to work in an environment and climate of trust and shared responsibilities.",

            "carrieres_offres_mobile_profil" : "With an IT background and tangible experience, you are recognized for your ability to learn and your involvement in the subjects entrusted to you. You are independent and know how to ask for help when necessary. Your curiosity, your affection for technique and the ability to work in a team is essential. You have excellent interpersonal skills and like to share your knowledge. Your rigor, pugnacity, ability to adapt and thirst for challenge will allow you to fully understand the missions entrusted to you.",
            "carrieres_offres_mobile_requis1" : "Mobile development: Android or iOS.",
            "carrieres_offres_mobile_requis2" : "DevOps environment: Gitlab CI/CD, ongoing infrastructure containerization project.",
            "carrieres_offres_mobile_requis3" : "Development environment: at the discretion of the developer (Mac / Windows / Linux), free choice of IDE, licenses purchased if necessary by Ormaes (Intellij, GitKraken or other).",
            
            "carrieres_offres_mobile_souhait1" : "Flutter.",
            "carrieres_offres_mobile_souhait2" : "Payment module, NFC, Maps.",
            "carrieres_offres_mobile_souhait3" : "WEB: Javascript Vanilla, Angular 14.",
            "carrieres_offres_mobile_souhait4" : "Back-end SpringBoot Reactive, Spring, WebServices REST, RabbitMQ, SpringBatch.",
            "carrieres_offres_mobile_souhait5" : "Database : MongoDB, MariaDB.",
            "carrieres_offres_mobile_souhait6" : "Agile method adapted to the context (JIRA Agile).",

//Offredejob
            "offredejob_mission" : "Your Role and Responsibilities:",
            "offredejob_profil" : "Professional Expertise:",
            "offredejob_environnement" : "Technical Environment:",
            "offredejob_requis" : "Mandatory :",
            "offredejob_souhaite" : "Nice to have :",
            "offredejob_postuler" : "Apply",

//Contact->Form
            "form_cv" : "Your Resume is mandatory, find a way to join it 👀  ",
            "form_message" :"Send us a message 🚀",
            "form_nom" :"Last and First name*",
            "form_email" :"Email address*",
            "form_phone" :"Phone number",
            "form_value1" :"Software Engineering / Consulting Services",
            "form_value2" :"Jobs",
            "form_value3" :"Other",
            "form_parle" :"Tell us about your project*",
            "form_envoyer" :"Send",         
            "form_drag" :" or drag & drop",    
            "form_upload" :"Click to upload",    
            "form_pdf" :"PDF, JPG, JPEG",  

//Contact->Form->Snackbar
 "form_snackbar_success" : "Email sent successfully",
 "form_snackbar_fail" : "Failed to send email",

//Contact
            "contact_parlons" : "Let's talk about your project",
            "contact_qui" : "But before tell us who you are :)",
            "contact_title" : "Ormaes | Contact",
            "contact_creons" : "Let's do something together🤘",
            "contact_ecrire" : "Contact us by email ",
            "contact_mail" : "contact@ormaes.fr",
            "contact_carte" : "See the map",

// Contact_MailEnvoyé
            "mailenvoye" : "Thanks to have contacted us !",

//Contact_MailNonEnvoyé
            "mailnonenvoye" : "You have to wait a bit before sending an email",

//Contact_Spam
            "spam_impossible" : "Impossible to send more than one mail every 30 seconds",
            "spam_back" : "Back to homepage by clicking on the button below.",
            "spam_accueil" : "Homepage",

//Contact_ValidationMessage
            "validationmessage_succes" : "The message was sent successfully !",
            "validationmessage_back" : "Return to homepage by clicking on the button below.",
            "validationmessage_accueil" : "Homepage",

//LaVieChezOrmaes -> Page pas encore activée, contenu pas encore traduit

// MentionsLégales
            "mentions_mentions" : "Legal Notices",
            "mentions_editeurs" : "Site editors : ",
            "mentions_site" : "ORMAES website published by ORMAES",
            "mentions_ormaes" : "http://www.ormaes.fr",
            "mentions_sas" : "SAS with a capital of €4,000",
            "mentions_adresse1" : "79a rue de la plaine des bouchers ",
            "mentions_adresse2" : "67100 Strasbourg ",
            "mentions_rcs" : "Registered with the Strasbourg RCS under number 920 990 017",
            "mentions_tva" : "EU Intracommunity VAT number: FR44 920 990 017",
            "mentions_representants" : "Legal representatives: ",
            "mentions_laurenteric" : "Eric JOLIN, Laurent BURGER ",
            "mentions_contact" : "Contact",
            "mentions_siege" : "The head office :",
            "mentions_adresse3" : "79a rue de la plaine des bouchers, 67100 Strasbourg, France  ",
            "mentions_telephone" : "Reachable at +33 (0)3 88 61 02 08",
            "mentions_courriel" : "By email : contact@ormaes.fr ",
            "mentions_dirpub" : "Publication directors: ",
            "mentions_ericlaurent" : "Eric JOLIN, Laurent BURGER",
            "mentions_cgu" : "Terms of Service",
            "mentions_siteaccessible" : "The website is accessible via the following addresses:",
            "mentions_ormaes2" : "www.ormaes.fr",
            "mentions_cgufraccept" : "It is operated in compliance with French legislation. The use of this site is governed by these general conditions of use. By using the site, you acknowledge having read these conditions and having accepted them. They may be modified at any time and without notice. Ormaes SAS cannot be held responsible for improper use of the service.",
            "mentions_hebergeur" : "Site host",
            "mentions_ovh" : "OVH ",
            "mentions_ovhsiege" : "Head Office : 2 rue Kellermann - 59100 Roubaix - France.",
            "mentions_ovhadresse" : "OVH SAS is a subsidiary of the company OVH Groupe SA, a company registered with the RCS of Lille under number 537 407 926 located at 2, rue Kellermann, 59100 Roubaix.",
            "mentions_limiteresp" : "Limits of liability",
            "mentions_maj" : "The information contained on the website is as accurate as possible. It is updated periodically, but may nevertheless contain inaccuracies, omissions, gaps or information that is no longer up to date. If you notice an omission or error or more generally what appears to be a malfunction, we thank you for reporting it to us by email, describing the problem as precisely as possible so that we can process the subject of your request, as soon as possible.",
            "mentions_risques" : "Any content downloaded is done at the user's own risk and under their sole responsibility. Consequently, ORMAES cannot be held responsible for any damage suffered by the user's computer or any loss of data following downloading.",
            "mentions_images" : "The images are not contractual.",
            "mentions_liens" : "Ormaes cannot be held liable for external links on the website to other resources on the Internet.",
            "mentions_litiges" : "Disputes, applicable law and competent court ",
            "mentions_litiges_text" : "This site and conditions of use are governed by French law. Any dispute or litigation will fall under the exclusive jurisdiction of the courts on which the Ormaes head office depends.",
            "mentions_pi" : "Intellectual property",
            "mentions_pi_donnees" : "The structure of the site, and in a non-limiting manner, the contributions, images, texts, videos, sounds, graphics, icons, downloadable documents, databases, web services and any other element making up the site are the exclusive property of ORMAES, with the exception of brands, images and logos of partner companies, clients, or authors which are the exclusive property of the companies they represent.",
            "mentions_pi_fr" : "This entire site is subject to French legislation regarding copyright and intellectual property.",
            "mentions_pi_repro" : "Any use, total or partial reproduction, of the site and the elements which compose it by any process whatsoever, constitutes an infringement punishable by the Intellectual Property Code. Failure to comply with this prohibition constitutes an infringement which may result in civil and criminal liability for the infringer.",
            "mentions_confid" : "Confidentiality",
            "mentions_confid_text" : "All our online services limit the collection of personal data to what is strictly necessary (data minimization). Your personal data is confidential and is not communicated to third parties. The data collected on our website is not sold to third parties.",
            "mentions_pol_cookies" : "Cookie Policy",
            "mentions_cookie_def" : "What is a cookie",
            "mentions_cookie_def_text" : "A cookie is a small file made up of letters and numbers that is downloaded to your computer when you access certain websites. In general, cookies allow a website to store technical navigation data on your computer, such as the identification of the user's computer.",
            "mentions_cookie_pourquoi" : "Why we use cookies",
            "mentions_cookie_expl" : "We may use cookies and other similar technologies for a number of reasons, for example: i) for security or fraud protection purposes, and to identify and prevent cyber attacks, ii) for statistical and display purposes iii) to monitor and analyze the performance, operation and effectiveness of our service and iv) to improve the user experience.",
            "mentions_cookie_disable" : "It is possible to prevent your browser from accepting cookies by changing the relevant settings in your browser.",
            "mentions_cookie_suppr" : "Please note that deleting our cookies or disabling future cookies or tracking technologies may prevent you from accessing certain areas or features of our Services, or may otherwise negatively affect your user experience.",
            "mentions_cookie_politique" : "We reserve the right to change our cookie policy. We encourage you to regularly check this page for the latest information on cookies.",
            "mentions_remerciements" : "Thanks",
            "mentions_bonne_nav" : "Finally, above all, we wish you good browsing and are very happy that you had the tenacity to read all of our Terms of Service & Legal Notices, which is worthy of mention. We remain at your disposal in real life. Digital is great, real life is even better.",

//Teapot
            "teapot_iam" : "I'm a TEAPOT",
            "teapot_418" : "Error code : 418",
            "teapot_alt" : "Teapot",
            "teapot_attention" : "Be careful, you have activated teapot mode! Prepare yourself for an infusion of madness and boiling humor",
            "teapot_accueil" : "Homepage",

//Error404
            "error_oups" : "Oops !",
            "error_encours_dev" : "The page you're looking for is still in progress !",
            "error_code_erreur" : "Error Code : 404",
            "error_accueil" : "Homepage",

// NotreOffre -> Accordion.js
// 01. Développement sur mesure
            "notreoffre_01_titre" : "01. Custom application development",
            "notreoffre_01_paragraphe1" : "Your business is specific, your functional and technical requirements are also specific, or quite simply, there is no software on the market that meets your needs. We are the partner you need.",
            "notreoffre_01_paragraphe2" : "Our job is to quickly understand your business, your particularities, your organization and your Information System. We have successfully completed projects in a wide variety of business areas: transportation ticketing, electronic payment systems, retail cash register systems, professional training and educational information systems, production in heavy industry, connected factories in the industrial sector, landline telephony, optimization of robots for drug delivery, radiology diagnostics, irradiation dose control using scanners, end customer platform in electricity retail, etc.",
            "notreoffre_01_paragraphe3" : "The clever mix of a strong interest in the business and a passion for technologies, as well as our significant experience of complex projects, will give you significant confidence in our ability to adapt to your challenges and more particularly a mastery of your project.",
            "notreoffre_01_paragraphe4" : "Our strength lies in our ability to integrate a lot of complexity inherent to your business, your information system or when several information systems/softwares must communicate with each other.",
            "notreoffre_01_paragraphe5" : "Our goal: To know how to make the complicated simple for you.",
            "notreoffre_01_paragraphe6" : "We constitute a multidisciplinary and complementary team covering the professions involved in the IT project cycle:",
            "notreoffre_01_li1" : "Upstream support allowing the formalization of the expression of needs and requirements",
            "notreoffre_01_li2" : "Help with choice: make or buy",
            "notreoffre_01_li3" : "Business Analysis",
            "notreoffre_01_li4" : "Software architecture and urbanization",
            "notreoffre_01_li5" : "Project management",
            "notreoffre_01_li6" : "Setting up environments and tools: Middleware and infrastructure expertise",
            "notreoffre_01_li7" : "Software development",
            "notreoffre_01_li8" : "Integration of the software application into the existing system as well as upstream and downstream applications",
            "notreoffre_01_li9" : "Users Acceptance tests support",
            "notreoffre_01_li10" : "Support for deployment",
            "notreoffre_01_li11" : "Application/software Maintenance outsourcing in production and technical support ",
            "notreoffre_01_p1" : "The types of applications we design:",
            "notreoffre_01_li12" : "Web applications that are “responsive” and “adaptive”",
            "notreoffre_01_li13" : "Desktop applications",
            "notreoffre_01_li14" : "Server-type applications",
            "notreoffre_01_li15" : "IOS and Android mobile applications",
            "notreoffre_01_li16" : "Portals using CMS",
            "notreoffre_01_paragraphe7" : "As software development is constantly becoming more complex, we also have a large network of partners enabling us to add cutting-edge expertise in different fields to the projects we carry out.",

            // 02. Intération de systèmes
            "notreoffre_02_titre" : "02. Software integration",
            "notreoffre_02_paragraphe1" : "Software integration encompasses 2 main facets which each require specific skills:",
            "notreoffre_02_paragraphe2" : "The integration of Frameworks or specific libraries:",
            "notreoffre_02_li1" : "This involves taking advantage of components already produced in order to accelerate specific developments, making it possible to concentrate on the creation of added business value in the project,",
            "notreoffre_02_li2" : "This integration requires technical development expertise, and an ability to gain perspective to choose the components to integrate while ensuring that a coherent, efficient and sustainable project is maintained,",
            "notreoffre_02_li3" : "The framework integrator is part of the development team, he is often technical leader.",
            "notreoffre_02_paragraphe3" : "Integration of generic software solutions:",
            "notreoffre_02_li4" : "This involves integrating market software solutions (proprietary as well as open source) into an existing information system,",
            "notreoffre_02_li5" : "The integration of generic software solution does not require knowledge of the software to be integrated, but requires having good analytical skills, skills in architecture and urbanization and experience in solution analysis and impact study,",
            "notreoffre_02_li6" : "The software integrator must have team spirit, demonstrate great adaptability and an open mind, in order to be able to integrate all types of solutions,",
            "notreoffre_02_li7" : "The software integrator is integrated into the architecture team and/or the infrastructure team. He is able to develop a list of tasks to be carried out for the integration, in order to entrust it to the development team or to carry out these tasks himself.",

// 03. Expertises Techniques
            "notreoffre_03_titre" : "03. Technical Expertise",
            "notreoffre_03_paragraphe1" : "We have strong expertise in developing tailor-made applications in project mode and integration. This involves the intensive use of methods, tools, frameworks and languages in which we have developed expertise. We regularly intervene, on request, in expertise, on services requiring these specific know-how:",
            "notreoffre_03_li1" : "Workload estimation and costing of projects using charts",
            "notreoffre_03_li2" : "Software architecture and urbanization of information systems: Help with technological choice, reduction of technical debt, technical migration (example: Java 9 to Java 19 or Spring 3 to Spring 6)",
            "notreoffre_03_li3" : "Technical overhawl",
            "notreoffre_03_li4" : "Technical upgrade of information systems",
            "notreoffre_03_li5" : "Complete configuration, tuning and workflow configuration with Atlassian's Jira solution. Backlog recovery from a third-party solution.",
            "notreoffre_03_li6" : "Advanced Java, Spring, JSF",
            "notreoffre_03_li7" : "Android (Kotlin), IOS (Swift), Dart / Flutter or ReactNative",
            "notreoffre_03_li8" : "Angular, NodeJS, ReactJS ",
            "notreoffre_03_li9" : "C#, ASP.NET, .NET Framework",
            "notreoffre_03_li10" : "C++",
            "notreoffre_03_li11" : "Python",
            "notreoffre_03_li12" : "Talend ETL",
            "notreoffre_03_li13" : "OpenAPI",
            "notreoffre_03_li14" : "Liferay 6.2 up to version 7.3 (DXP), OpenCMS (Alcakon)",
            "notreoffre_03_li15" : "Code Quality and Unit Testing: JUnit, NUnit, Mockito, Winium, Selenium, SonarCube, Lint",
            "notreoffre_03_li16" : "DBMS / SQL / NOSQL / PLSQL / ORM: Creation, analysis and optimization of queries (MySQL, Postgresql, Oracle, Firebird, SQL Server, MariaDB). Setting up Cluster/Replicaset and performing complex queries with MongoDB",
            "notreoffre_03_li17" : "Performance testing: testing strategy, setting up environments, load testing, limit testing, robustness testing, JMeter, Loadrunner, Taurus",
            "notreoffre_03_li18" : "CI/CD chain",
            "notreoffre_03_li19" : "Automatic Document Reading and Recognition (RAD/LAD): Tesseract, OpenCV, ABBYY",
            "notreoffre_03_paragraphe2" : "Although we love Prévert, we limit ourselves to our core expertise and stop the list here.",

// Footer
"footer_accueil" : "Home",
"footer_qui" : "Who are we",
"footer_offre" : "Our offer",          
"footer_carrieres" : "Careers",          
"footer_contact" : "Contact",
"footer_mentions" : "Legal Notices", 
"footer_droits" : "2024 Ormaes. All rights reserved.",

//MenuDrawer
"menudrawer_item1" : "Services",            
"menudrawer_subitem1.1" : "Software development",
"menudrawer_subitem1.2" : "Technical Expertise",
"menudrawer_subitem1.3" : "Industry Expertise",
"menudrawer_item2" : "Ecosystem",       
"menudrawer_item3" : "Case Study",       
"menudrawer_item4" : "Carreers", 
"menudrawer_retour" : "Back to main menu",       
"menudrawer_demarrer_projet" : "Start a projet",    

// a supprimer  variable à copier dans le code       {t("form_snackbar_success")}         

            }
          }
    }, 

    interpolation: {
      escapeValue: false
    }
  });
export default i18n;
